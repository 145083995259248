<template>
  <div class="main">
    <div class="swiper" :style="{height:swiper_height+'px'}">
      <!-- <u-swiper :list="list" :height="swiper_height*2" mode="dot" interval="3000"></u-swiper> -->
      <img src="@/assets/imgs/B1.png" :height="swiper_height*2" />
      <div class="brand">
        <div class="content">
          <div class="logo">
            <img class="midVerti" src="@/assets/img_pplogo@3x.png" mode />
          </div>
          <div class="slogan">
            <div class="imp spName">
              <span>云蜂场</span>
              <span>卟啉卟啉小蜜盒250g巢蜜</span>
            </div>
            <div class="from">M-APIARY移动蜂车全程监控生产</div>
          </div>
          <div class="clear"></div>
        </div>
      </div>
    </div>
    <div class="buy_Info info">
      <div class="title">
        <div class="icon">
          <img class="midVerti" src="@/assets/icon_buyinfo@3x.png" mode />
        </div>
        <div class="name">购买信息</div>
      </div>
      <div class="content">
        <img src="@/assets/img_chushou@3x.png" mode />
        <!-- <myCells :cellList="buyList"></myCells> -->
        <van-cell-group>
          <van-cell
            v-for="(item,index) in buyList"
            :key="index"
            :title="item.label"
            :value="item.value"
            value-class="valueCl"
          />
        </van-cell-group>
        <button shape="circle" class="again adopt">再次购买</button>
      </div>
    </div>
    <div :class="isShow ? 'pro_Info_show' :'pro_Info'" class="info">
      <div class="title">
        <div class="icon">
          <img class="midVerti" src="@/assets/icon_spinfo@3x.png" mode />
        </div>
        <div class="name">商品批次信息</div>
      </div>
      <div class="content">
        <!-- <myCells :cellList="spList" class="list"></myCells> -->
        <van-cell-group>
          <van-cell
            v-for="(item,index) in spList"
            :key="index"
            :title="item.label"
            :value="item.value"
            value-class="valueCl"
          />
        </van-cell-group>
      </div>
    </div>
    <div class="quality_Info info">
      <div class="title">
        <div class="icon">
          <img class="midVerti" src="@/assets/icon_zjinfo@3x.png" mode />
        </div>
        <div class="name">质检信息</div>
      </div>
      <div class="content">
        <!-- <myCells :cellList="zjList"></myCells> -->
        <van-cell-group>
          <van-cell
            v-for="(item,index) in zjList"
            :key="index"
            :title="item.label"
            :value="item.value"
            value-class="valueCl"
          />
        </van-cell-group>
        <div class="zjRes">
          <span>质检结果</span>
          <div class="imgs">
            <img
              v-for="(item,index) in zjPicList"
              :key="index"
              :src="item"
              mode
              @click="showPic(item)"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="recommend_Info info">
      <div class="title">
        <div class="icon">
          <img class="midVerti" src="@/assets/icon_tjinfo@3x.png" mode />
        </div>
        <div class="name">推荐商品</div>
      </div>
      <div class="content">
        <div class="item" v-for="(item,index) in tjProList" :key="index">
          <img :src="item.imgUrl" mode />
          <div class="rcTitle">{{item.name}}</div>
        </div>
      </div>
    </div>
    <div class="box_Info info">
      <div class="title">
        <div class="icon">
          <img class="midVerti" src="@/assets/icon_tjinfo@3x.png" mode />
        </div>
        <div class="name">蜂箱信息</div>
      </div>
      <div class="content">
        <div class="mask">
          <button class="again adopt" shape="circle">认养蜂箱</button>
        </div>
        <div class="map">
          <img src="@/assets/map.png" />
        </div>
        <div class="displayInfo">
          <div class="item">
            <div class="boxtitle">箱内温度</div>
            <div class="boxIn">
              <div class="imgs">
                <img class="midVerti" src="@/assets/icon_fx_wendu@3x.png" mode />
              </div>
              <span>
                37.2
                <span class="unit">°C</span>
              </span>
            </div>
          </div>
          <div class="item">
            <div class="boxtitle">箱内湿度</div>
            <div class="boxIn">
              <div class="imgs">
                <img class="midVerti" src="@/assets/icon_fx_shidu@3x.png" mode />
              </div>
              <span>
                68.9
                <span class="unit">%</span>
              </span>
            </div>
          </div>
          <div class="item">
            <div class="boxtitle">当前重量</div>
            <div class="boxIn">
              <div class="imgs">
                <img class="midVerti" src="@/assets/icon_fx_weight@3x.png" mode />
              </div>
              <span>
                17.5
                <span class="unit">kg</span>
              </span>
            </div>
          </div>
          <div class="item">
            <div class="boxtitle">当日进出量</div>
            <div class="boxIn">
              <div class="imgs">
                <img class="midVerti" src="@/assets/icon_fx_inout@3x.png" mode />
              </div>
              <span>
                38546
                <span class="unit">只</span>
              </span>
            </div>
          </div>
          <div class="item">
            <div class="boxtitle">箱内声音</div>
            <div class="boxIn">
              <div class="imgs">
                <img class="midVerti" src="@/assets/icon_fx_voice@3x.png" mode />
              </div>
              <span>
                335
                <span class="unit">Hz</span>
              </span>
            </div>
          </div>
          <div class="item">
            <div class="boxtitle">当前蜜源</div>
            <div class="boxIn">
              <div class="imgs">
                <img class="midVerti" src="@/assets/icon_fx_miyuan@3x.png" mode />
              </div>
              <span>百花蜜</span>
            </div>
          </div>
        </div>
        <div class="updateDate">
          <div class="imgs">
            <img src="@/assets/img_dataIcon@3x.png" />
          </div>
          <span>2022-09-07 15:32 更新</span>
        </div>
        <div class="notice">注：本智能蜂箱仅为生产批次蜜源来源蜂箱之一，因季节花期变迁，与溯源产品蜜源可能不同。</div>
      </div>
    </div>
    <div class="source_Info info">
      <div class="title">
        <div class="icon">
          <img class="midVerti" src="@/assets/icon_tjinfo@3x.png" mode />
        </div>
        <div class="name">溯源信息</div>
      </div>
      <div class="content">
        <div class="item">
          <img src="@/assets/bg_sy_line1@3x.png" class="first_line" mode />
          <img class="midVerti logo" src="@/assets/img_sypic1e@3x.png" mode />
          <div class="details midVerti">
            <div class="first">采蜜 2022年07月22日 14:21</div>
            <div class="produce">
              源蜜来自
              <span class="imp">3</span>个蜂场，总采集
              <span class="imp">404脾</span>
              源蜜波美度品质
              <span class="imp">42.2°~42.5°</span>
              <button class="btns" @click="factoryShow = !factoryShow">
                蜂场详细
                <van-icon v-if="factoryShow" name="arrow-down"></van-icon>
                <van-icon v-else name="arrow"></van-icon>
              </button>
            </div>
          </div>
        </div>
        <div class="moreInfo" v-if="factoryShow">
          <div class="factory">
            <div class="title">批次源蜜蜂场溯源：</div>
            <div class="fact">
              <div class="fac_item" v-for="(item,index) in factory_List" :key="index">
                <div>{{item.name}}</div>
                <div>
                  提供&nbsp;
                  <span class="imp">{{item.weight}}脾</span>&nbsp;源蜜
                </div>
              </div>
              <img class="auth" src="@/assets/img_qukuaizhen@3x.png" mode />
              <div class="notice">天府蜂谷提供蜂蜜从蜂场源头到灌装，以及中途物流、仓储等全环节溯源，确保消费者购买的每一瓶蜂蜜的品质。</div>
            </div>
          </div>
        </div>

        <div class="item">
          <img class="sec_line" src="@/assets/bg_sy_line2@3x.png" mode />
          <div class="details midVerti">
            <div class="first">灌装 2022年09月20日 10:39</div>
            <div class="produce">
              采用
              <span class="imp">源生源蜜</span>、
              <span class="imp">紫外线杀菌</span>工艺
              总灌装
              <span class="imp">2000</span> 瓶(盒)
            </div>
            <button class="btns" @click="fillShow = !fillShow">
              灌装详细
              <van-icon v-if="fillShow" name="arrow-down"></van-icon>
              <van-icon v-else name="arrow"></van-icon>
            </button>
          </div>
          <img class="midVerti logo" src="@/assets/img_sypic2e@3x.png" mode />
        </div>
        <div class="moreInfo" v-if="fillShow">
          <div class="fill">
            <div class="title">批次灌装溯源信息：</div>
            <div class="fill_content">
              <div class="fill_item" v-for="(item,index) in fillSource" :key="index">
                <span>{{item.label}}</span>
                <span class="imp">{{item.value}}</span>
              </div>
            </div>
            <img
              class="auth"
              src="@/assets/img_qukuaizhen@3x.png"
              mode
              style="right:0;bottom: 20rpx;"
            />
            <div class="notice">天府蜂谷提供蜂蜜从蜂场源头到灌装，以及中途物流、仓储等全环节溯源，确保消费者购买的每一瓶蜂蜜的品质。</div>
          </div>
        </div>
        <div class="item">
          <img src="@/assets/bg_sy_line4@3x.png" class="query_line" mode v-if="isQuery" />
          <img src="@/assets/bg_sy_line3@3x.png" class="noQuery_line" mode v-else />
          <img class="midVerti logo" src="@/assets/img_sypic3e@3x.png" mode />
          <div class="details midVerti">
            <div class="first">发货 2022年09月10日 14:21</div>
            <div class="produce">
              从
              <span class="imp">成都市双流区</span>发往
              <span class="imp">成都市双流区</span>
              跨越
              <span class="imp">13.5</span>公里
              历时
              <span class="imp">22小时</span>抵达用户手中
              <button class="btns" @click="expressShow = !expressShow">
                物流详细
                <van-icon v-if="expressShow" name="arrow-down"></van-icon>
                <van-icon v-else name="arrow"></van-icon>
              </button>
            </div>
          </div>
        </div>
        <div class="moreInfo" v-if="expressShow">
          <div class="express">
            <div class="title">发货物流详细信息：</div>
            <van-steps direction="vertical" active-color="#FDD200">
              <van-step :active="active" v-for="(item,index) in expressList" :key="index">
                <h3>{{item.label}}</h3>
                <p>{{item.time}}</p>
              </van-step>
            </van-steps>
          </div>
        </div>
        <div class="item" v-if="isQuery">
          <img src="@/assets/bg_sy_line5@3x.png" class="last_line" mode />
          <div class="details midVerti">
            <div class="first">防伪查询 2022年09月12日 10:42</div>
            <div class="produce">
              在
              <span class="imp">四川省 成都市 双流区</span>首次扫码查询
            </div>
          </div>
          <img class="midVerti logo" src="@/assets/img_sypic4e@3x.png" mode />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// const baseUrl = "http://shop.beeaiot.com/trace";

// import mycells from "@/components/cell.vue";
export default {
  // components: {
  //   mycells
  // },
  data() {
    return {
      marks: [
        {
          latitude: 30.492394,
          longitude: 104.073434,
          iconPath: "@/assets/img_hiveloct@3x.png",
          callout: {
            content: "861193043668764\n四川省成都市双流区天府大道南段890号",
            display: "ALWAYS",
            padding: 20,
            borderRadius: 10,
            textAlign: "center"
          }
        }
      ],
      isQuery: true,
      expressShow: false,
      fillShow: false,
      factoryShow: false,
      showFill: false, //灌装的弹窗显示
      isShow: false,
      swiper_height: 0,
      list: [require("@/assets/imgs/B1.png")],
      zjPicList: [
        require("@/assets/imgs/z1.png"),
        require("@/assets/imgs/z2.png")
      ],
      tjProList: [
        {
          name: "时节蜜语 [蜜+] 天然42°槐花蜜180g旅行便携装",
          imgUrl: require("@/assets/imgs/1.png")
        },
        {
          name: "新疆原产43°枣花蜜 网红办公美颜蜂蜜",
          imgUrl: require("@/assets/imgs/2.png")
        },
        {
          name: "东北珍藏长白山雪蜜42.8° 一年一季冬天秘密",
          imgUrl: require("@/assets/imgs/3.png")
        }
      ],
      buyList: [
        {
          label: "防伪编号",
          value: "YX10E080121233"
        },
        {
          label: "首次登记时间",
          value: "2022年09月12日 10:42"
        },
        {
          label: "首次登记地点",
          value: "四川省   成都市"
        }
      ],
      spList: [
        {
          label: "品牌",
          value: "云蜂场"
        },
        {
          label: "品名",
          value: "卟啉卟啉小蜜盒"
        },
        {
          label: "源蜜产地",
          value: "青海省德令哈市"
        },
        {
          label: "产品规格",
          value: "250g"
        },
        {
          label: "加工批号",
          value: "SP202206213882"
        },
        {
          label: "出品企业",
          value: "四川天府蜂谷科技有限公司"
        }
      ],
      zjList: [
        {
          label: "质检单位",
          value: "四川健生堂农业开发有限公司"
        },
        {
          label: "质检时间",
          value: "2022年09月14日"
        }
      ],
      fillSource: [
        {
          label: "灌装时间",
          value: "2022年09月20日 10:39"
        },
        {
          label: "灌装批次号",
          value: "SP202206213882"
        },
        {
          label: "投入源蜜数",
          value: "340脾"
        },
        {
          label: "灌装工厂",
          value: "广汉市蜂云堂养蜂专业合作社"
        },
        {
          label: "灌装工艺 — 过滤",
          value: "三级过滤"
        },
        {
          label: "灌装工艺 — 浓缩",
          value: "未浓缩"
        },
        {
          label: "灌装规格",
          value: "250g"
        },
        {
          label: "灌装数量",
          value: "2000瓶(盒)"
        },
        {
          label: "灌装前蜂蜜浓度检测",
          value: "42.4°"
        },
        {
          label: "食品加工安全许可证",
          value: "SC12651068100013"
        },
        {
          label: "灌装执行标准",
          value: "GB-14963"
        },
        {
          label: "灌装操作员",
          value: "李*龙"
        },
        {
          label: "灌装工厂地点",
          value: "四川省德阳市广汉市三水镇中心村8组"
        }
      ],
      factory_List: [
        {
          name: "恒源养殖专业合作社",
          weight: 122
        },
        {
          name: "格尔木蜂之语蜂业",
          weight: 217
        },
        {
          name: "达木梭梭林蜂场",
          weight: 65
        }
      ],
      expressList: [
        {
          label: "快件已签收，签收人：凭取件码取货",
          time: "2022-09-11 18:34:19",
          bgColor: "#FDD200"
        },
        {
          label: "快件已暂存至花样年家天下菜鸟驿站",
          time: "2022-09-11 12:29:39"
        },
        {
          label: "四川省成都市双流区  何其(185****6457)正在派件",
          time: "2022-09-11 09:45:22"
        },
        {
          label: "成都双流转运中心 已发出",
          time: "2022-09-12 01:56:45"
        },
        {
          label: "四川省成都市双流区 董鹏飞(13985411156) 已揽收",
          time: "2022-09-10 18:34:42"
        },
        {
          label: "商家已发货，通知圆通快递取件",
          time: "2022-09-10 14:21:12"
        }
      ]
    };
  },

  mounted() {
    this.setSize();
  },
  onLoad() {},
  methods: {
    setSize() {
      let width = document.documentElement.clientWidth;
      let rate = 1080 / 640;
      let height = width / rate;
      this.swiper_height = height;
      console.log(height, "高度");
    },
    toggle() {
      this.isShow = !this.isShow;
    },
    showPic(item) {
      // uni.previewimg({
      // 	urls: [item]
      // })
    }
  }
};
</script>

<style lang="scss">
.main {
  width: 100%;
  min-height: 100%;
  background: #f0f6ff;
}

.swiper {
  width: 100%;
  height: 320px;
  position: relative;
  img {
    width: 100%;
    height: 100%;
  }
  .u-swiper-indicator {
    bottom: 70px !important;
  }
}

.brand {
  width: 93%;
  height: 105px;
  margin: 0 auto;
  position: absolute;
  top: 87.5%;
  left: 3.5%;
  border-radius: 10px;
  box-shadow: 0px 5px 5px 0 rgba($color: #fdd200, $alpha: 0.4);
  z-index: 10000;
  .content {
    width: 100%;
    height: 100%;
    // position: absolute;
    // top: -50%;
    background: #fdd200;
    border-radius: 10px;

    .logo {
      width: 130px;
      height: 100%;
      text-align: center;
      float: left;

      img {
        width: 100px;
        height: 75px;
      }
    }

    .slogan {
      width: calc(100% - 130px);
      height: 100%;
      float: left;

      .spName {
        margin-top: 15px;

        span {
          width: 100%;
          display: inline-block;
          height: 18px;
          line-height: 18px;
          font-size: 14px;
        }
      }

      .from {
        margin-top: 5px;
        font-size: 14px;
        color: #f04343;
      }
    }

    .clear {
      clear: both;
    }
  }
}

.info {
  .title {
    width: 90%;
    height: 40px;
    margin: 0 auto;
    display: flex;

    .icon {
      width: 40px;
      height: 40px;
      text-align: center;

      img {
        width: 18px;
        height: 18px;
      }
    }

    .name {
      flex: 3;
      height: 40px;
      line-height: 40px;
      font-size: 15px;
      font-weight: 900; // text-indent: 10rpx;
    }
  }

  .content {
    width: 88%;
    height: 210px;
    margin: 0 auto;
  }
}

.notice {
  background: #f8f8f8;
  color: #777777;
  font-size: 13px;
  padding: 10px;
  line-height: 20px;
}

.buy_Info {
  width: 100%;
  height: 250px;
  background-color: #ffffff;
  padding-top: 90px;

  .content {
    position: relative;

    img {
      width: 90px;
      height: 90px;
      position: absolute;
      right: 0;
      z-index: 10000;
    }
  }

  .again {
    margin: 13px auto;
    width: 150px;
    height: 35px;
    line-height: 35px;
    font-size: 15px;
    background-color: #ff5e43;
    color: #ffffff;
    box-shadow: 0px 2px 3px 0 rgba($color: #ff5e43, $alpha: 0.4);
    text-align: center;
  }
}

.pro_Info_show {
  width: 100%;
  height: 290px;
  background-color: #ffffff;
  margin-top: 8px;

  .content {
    width: 88%;
    height: 250px;
    margin: 0 auto;
    position: relative;

    .list {
      width: 100%;
      height: 250px;
      overflow-y: hidden;
    }

    .moreBtn {
      position: absolute;
      width: 100%;
      background: rgba($color: #ffffff, $alpha: 0.8);
      height: 30px;
      line-height: 30px;
      bottom: 0;
      text-align: center;
      // filter: blur(8px);
    }
  }
}

.pro_Info {
  width: 100%;
  height: 330px;
  background-color: #ffffff;
  margin-top: 8px;

  .content {
    width: 88%;
    height: 290px;
    margin: 0 auto;
    position: relative;

    .list {
      width: 100%;
      height: 210px;
    }

    .moreBtn {
      position: absolute;
      width: 100%;
      background: rgba($color: #ffffff, $alpha: 0.8);
      height: 30px;
      line-height: 30px;
      bottom: 0;
      text-align: center;
      color: #999999;
    }
  }
}

.quality_Info {
  width: 100%;
  height: 230px;
  background-color: #ffffff;
  margin-top: 8px;

  .zjRes {
    margin-top: 8px;
    display: flex;

    span {
      flex: 1;
      color: #323233;
      font-size: 14px;
      text-indent: 15px;
    }

    .imgs {
      flex: 2;

      img {
        width: 70px;
        height: 70px;
        padding-left: 13px;
      }
    }
  }
}

.recommend_Info {
  width: 100%;
  height: 250px;
  background-color: #ffffff;
  margin-top: 8px;

  .content {
    width: 90%;
    height: 210px;
    margin: 10px auto;
    overflow-x: auto;
    display: flex;

    .item:nth-last-of-type() {
      margin-right: 0;
    }

    .item {
      width: 145px;
      height: 145px;
      text-align: center;
      margin-right: 10px;

      img {
        width: 145px;
        height: 145px;
      }

      .rcTitle {
        color: #222222;
        font-size: 14px;
        line-height: 20px;
        text-align: left;
      }
    }
  }
}

.box_Info {
  width: 100%;
  min-height: 550px;
  background-color: #ffffff;
  margin-top: 8px;
  padding-bottom: 20px;

  .again {
    margin-top: 13px;
    width: 150px;
    height: 35px;
    line-height: 35px;
    font-size: 14px;
    background-color: #ff5e43;
    color: #ffffff;
    box-shadow: 0px 2px 3px 0 rgba($color: #ff5e43, $alpha: 0.4);
  }

  .content {
    width: 90%;
    height: 510px;
    margin: 10px auto;

    .mask {
      position: absolute;
      width: 95%;
      height: 300px;
      // background:pink;
      z-index: 100000;

      .adopt {
        position: absolute;
        bottom: 20px;
        left: calc((100% - 150px) / 2);
      }
    }

    .map {
      width: 95%;
      height: 300px;
      margin: 0 auto;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .displayInfo {
      width: 95%;
      min-height: 120px;
      margin: 0 auto;
      // background: #19BE6B;
      display: flex;
      flex-wrap: wrap;

      .item:nth-of-type(3n-1) {
        text-align: left;
        flex: 2;

        .boxIn {
          margin: 0 auto;
        }
      }

      .item:nth-of-type(3n-2) {
        text-align: left;
        flex: 2;
      }

      .item:nth-of-type(3n) {
        text-align: left;
        flex: 1;

        .boxIn {
          float: right;
        }
      }

      .item {
        width: 33%;

        .boxtitle {
          width: 100%;
          height: 30px;
          line-height: 30px;
          font-size: 13px;
          color: #999999;
        }

        .boxIn {
          width: 100px;
          // font-size: 20rpx;
          height: 35px;
          line-height: 35px;
          display: flex;

          .imgs {
            width: 20px;
            height: 35px;
          }

          img {
            width: 20px;
            height: 20px;
          }

          text {
            font-size: 16px;
            font-weight: bold;
            color: #222222;
          }

          .unit {
            font-size: 10px;
          }
        }
      }
    }

    .updateDate {
      height: 30px;
      line-height: 30px;
      color: #777777;
      font-size: 26rpx;
      display: flex;

      .imgs {
        width: 30px;
        height: 30px;
        text-align: center;

        img {
          width: 15px;
          height: 15px;
          margin-top: 8px;
        }
      }

      text {
        display: inline-block;
        width: 150px;
        height: 30px;
        line-height: 30px;
      }
    }
  }
}

.from_Info {
  width: 100%;
  height: 320px;
  background-color: #ffffff;
  margin-top: 8px;

  .content {
    width: 90%;
    height: 280px;
    margin: 0 auto;

    .video {
      width: 100%;
      height: 200px;
    }

    .moreInfo {
      width: 100%;
      height: 170px;
      background-color: #f8f8f8;

      .addInfo {
        padding: 0 3%;
        display: flex;
        height: 40px;
        line-height: 40px;

        .name {
          flex: 1;
          text-align: left;
          color: #333333;
        }

        .num {
          flex: 1;
          text-align: right;
        }
      }

      .address {
        padding: 0 3%;
        color: #999999;
      }
    }
  }
}

.source_Info {
  width: 100%;
  height: 520px;
  background-color: #ffffff;
  margin-top: 8px;

  .content {
    width: 100%;
    height: 480px;
    position: relative;

    .moreInfo {
      background: #f5f5f5;
      width: 100%;

      .title {
        width: 100%;
        height: 35px;
        line-height: 35px;
        color: #999999;
        font-style: italic;
      }

      .auth {
        width: 125px;
        height: 98px;
        position: absolute;
        bottom: 50px;
        right: 75px;
      }
    }

    .fill {
      width: 93%;
      margin: 0 auto;
      min-height: 350px;
      position: relative;

      .fill_content {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
      }

      .fill_item {
        width: 48%;
        height: 65px;
        background: #ffffff;
        border-radius: 10px;
        margin-bottom: 10px;

        span {
          display: inline-block;
          width: 100%;
          height: 38px;
          text-indent: 10px;
          font-size: 14px;
        }

        span:nth-of-type(1) {
          line-height: 33px;
          color: #666666;
        }
      }

      .fill_item:nth-of-type(2n-1) {
        margin-right: 4%;
      }

      .fill_item:last-of-type {
        width: 100%;
        margin-right: 0;
      }
    }

    .express {
      width: 86%;
      margin: 0 auto;
      min-height: 350px;
      position: relative;
      padding-bottom: 5px;

      .node {
        width: 9px;
        height: 9px;
        border-radius: 50%;
      }

      .timeline:nth-of-type(1) {
        .u-order-desc {
          color: #222222;
          font-size: 14px;
          margin-bottom: 3px;
        }
      }

      .u-order-desc {
        color: #999999;
        font-size: 14px;
        margin-bottom: 4px;
      }

      .u-order-time {
        color: #999999;
        font-size: 13px;
      }
    }

    .factory {
      width: 93%;
      margin: 0 auto;
      min-height: 245px;
      position: relative;
      .fact {
        width: 100%;
      }
      // .auth {
      // 	width: 280rpx;
      // 	height: 221rpx;
      // 	position: absolute;
      // 	bottom: 100rpx;
      // 	right: 150rpx;
      // }

      .fac_item {
        width: calc(100% - 20px);
        padding: 0 10px;
        height: 35px;
        border-radius: 5px;
        background: #ffffff;
        line-height: 35px;
        display: flex;
        margin-bottom: 35px;
        div {
          display: inline-block;
          width: 60%;
        }

        div:nth-of-type(2) {
          width: 40%;
          text-align: right;
        }
        .imp {
          font-weight: 800;
        }
      }

      .notice {
        font-size: 13px;
        color: #999999;
      }
    }

    .item {
      width: 93%;
      height: 150px;
      margin: 0 auto;
      position: relative;

      .first_line {
        // width:133rpx;
        // height: 52rpx;
        width: 100%;
        height: 20px;
        position: absolute;
        bottom: 0;
        // left: 20%;
      }

      .sec_line {
        width: 100%;
        height: 100%;
        position: absolute;
        bottom: 0;
      }

      .query_line {
        width: 100%;
        height: 100%;
        position: absolute;
        bottom: 0;
      }

      .noQuery_line {
        width: 100%;
        height: 40px;
        position: absolute;
        top: 0;
      }

      .last_line {
        width: 100%;
        height: 25px;
        position: absolute;
        top: 0;
      }

      .logo {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        border: 2px solid #dedede;
        float: left;
      }

      .details {
        width: 220px;
        min-height: 90px;
        // background: #dedede;
        padding-left: 10px;
        float: left;

        .first {
          font-style: italic;
          height: 35px;
          line-height: 35px;
          font-size: 13px;
          color: #222222;
          font-weight: bold;
        }

        .produce {
          color: #999999;
          font-size: 13px;
          line-height: 20px;
          font-style: italic;
        }

        .btns {
          width: 100px;
          height: 25px;
          font-size: 13px;
          background: #fdd200;
          padding: 0;
          line-height: 25px;
          border-radius: 25px;
          font-style: normal;
          float: left;
          margin-top: 10px;
          color: #333333;
        }
      }
    }
  }
}

.u-default-hover {
  // color: #FFFFFF !important;
  border: 0 !important;
}
.valueCl {
  font-weight: bold;
  color: #333333 !important;
  flex: 2 !important;
}
.van-cell::after {
  border-bottom: 0 !important;
}
.van-hairline--top-bottom {
  border: 0 !important;
}
.van-cell-group {
  border-top: 0 !important;
  border-bottom: 0 !important;
}
.van-cell-group::after {
  border: 0 !important;
}

.imp {
  font-weight: 800;
}

.adopt {
  position: absolute;
  bottom: 20px;
  left: calc((100% - 150px) / 2);
}
</style>

/*
 * @Author: yang47 15228782447@163.com
 * @Date: 2022-10-21 17:05:53
 * @LastEditors: yang47 15228782447@163.com
 * @LastEditTime: 2022-10-21 17:37:14
 * @FilePath: \source1\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import App from './App.vue'

import vant from "vant"
import "vant/lib/index.css"
 
Vue.use(vant)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')

<!--
 * @Author: yang47 15228782447@163.com
 * @Date: 2022-10-21 17:05:53
 * @LastEditors: yang47 15228782447@163.com
 * @LastEditTime: 2022-10-21 17:19:06
 * @FilePath: \source1\src\App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div id="app">
    <HelloWorld />
  </div>
</template>

<script>
import HelloWorld from "./components/HelloWorld.vue";

export default {
  name: "App",
  components: {
    HelloWorld
  }
};
</script>

<style lang="scss">
* {
  padding: 0;
  margin: 0;
}
html,
body {
  width: 100%;
  height: 100%;
  #app {
    width: 100%;
    height: 100%;
  }
}

.midVerti {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
button {
  border: 0;
  border-radius: 20px;
}
</style>
